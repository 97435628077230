import { ActionButton } from '@/components/ActionButton';
import { Center } from '@mantine/core';
import { Box, Group, Text } from '@mantine/core';
import NextImage from 'next/image';
import { ReactNode } from 'react';
import classes from './SidebarActionButton.module.css';

export interface Props {
  children: ReactNode;
  name: string;
  description: string;
  logo: string | undefined;
  isLoading: boolean;
  isDisabled?: boolean;
  tooltipLabel?: string;
  isTooltipDisabled?: boolean;
  onClick: () => void;
}

export function SidebarActionButton({
  name,
  description,
  isDisabled,
  isLoading,
  children,
  logo,
  isTooltipDisabled,
  tooltipLabel,
  onClick,
}: Props) {
  return (
    <Box py={10} px={'md'} className={classes.actionButton}>
      <Group align={'center'} gap={'xs'}>
        {logo && (
          <Center w={25}>
            <NextImage alt={`${name} logo`} src={logo} width={22} height={22} />
          </Center>
        )}
        <Text size={'lg'} opacity={isDisabled ? 0.5 : 1}>
          {name}
        </Text>
      </Group>
      <Group
        mt={3}
        justify={'space-between'}
        align={'end'}
        wrap={'nowrap'}
        opacity={isDisabled ? 0.5 : 1}
      >
        <Text size={'xs'} c={'gray.6'}>
          {description}
        </Text>
      </Group>
      <ActionButton
        mt={'sm'}
        fullWidth
        withArrow={false}
        loading={isLoading}
        disabled={isDisabled}
        tooltipLabel={tooltipLabel}
        isTooltipDisabled={isTooltipDisabled}
        onClick={onClick}
      >
        {children}
      </ActionButton>
    </Box>
  );
}
