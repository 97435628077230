import { Asset, AssetAmount } from '@moonbeam-network/xcm-types';

export enum AppchainsEnum {
  All = 'All',
  EVM = 'EVM',
  Substrate = 'Substrate',
  Snaps = 'Snaps',
}

export enum ChainKey {
  Stagebox = 'stagebox',
  Dancebox = 'dancebox',
  Flashbox = 'flashbox',
  Local = 'local',
}

export enum ForkKey {
  DanceboxStaking = 'dancebox-staking',
  DanceboxProxyStaking = 'dancebox-proxy-staking',
  DanceboxRuntime = 'dancebox-runtime',
}

export interface BaseChainConfig {
  asset: Asset;
  decimals: number;
  genesisHash: string;
  name: string;
  parachainId: number;
  ss58Format: number;
  ws: string;
  getTransactionUrl: (blockHash: string, txHash: string) => string;
  getAssetAmount: (amount?: bigint) => AssetAmount;
}

export interface RelayChainConfig extends BaseChainConfig {
  fees: {
    reserveParaId: AssetAmount;
    register: AssetAmount;
  };
}

export interface ChainConfig extends BaseChainConfig {
  key: ChainKey;
  displayName: string;
  relay: RelayChainConfig;
  blockTime: number;
  blocksPerSession: number;
  http: string;
  runtime?: {
    block: number;
    session: number;
    version: 601;
  };
  fees: {
    register: AssetAmount;
    costPerBlock: AssetAmount;
    costPerSession: AssetAmount;
  };
  getAppChainUrls: (paraId: number, isEvm?: boolean) => AppChainUrls;
}

export interface ChainConfigProps {
  config: ChainConfig;
}

export interface ChopsticksFork {
  endpoint: string;
  port: number;
  blockNumber: number;
}

export interface AppchainConfig extends BaseChainConfig {
  isEthereum: boolean;
}

export interface AppChainUrls {
  host: string;
  ws: string;
  rpc: string;
  explorers: Explorer[];
}

export enum ExplorerName {
  PolkadotJs = 'polkadotJs',
  Evm = 'evm',
  SubscanDancebox = 'subscanDancebox',
  Blockscout = 'blockscout',
}

export interface Explorer {
  name: string;
  url?: string;
  logo?: string;
}

export type Explorers = Record<ExplorerName, Explorer>;
