import { useSidebar } from '@/components/Sidebar/state/sidebar.hooks';
import { ManageAppchainSidebar } from '@/components/pages/Dashboard/ManageAppchain/ManageAppchainSidebar';
import { ChainConfig } from '@/config';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { configAtom, paraIdAtom } from './ManageAppchain.atoms';
import { ManageAppchainTab } from './ManageAppchain.constants';

export function useManageAppchainState() {
  const { tab } = useSidebar();
  const paraId = useAtomValue(paraIdAtom);
  const config = useAtomValue(configAtom);

  return {
    tab: tab as ManageAppchainTab,
    paraId,
    config,
  };
}

export function useManageAppchain() {
  const { setTab, open, close } = useSidebar();

  const setParaId = useSetAtom(paraIdAtom);
  const setConfig = useSetAtom(configAtom);

  const openAppchainSidebar = useCallback(
    ({
      paraId,
      config,
      tab,
    }: {
      paraId: number;
      config: ChainConfig;
      tab?: ManageAppchainTab;
    }) => {
      setParaId(paraId);
      setConfig(config);
      setTab(tab || ManageAppchainTab.Management);
      open(<ManageAppchainSidebar closeOnBack={!!tab} />);
    },
    [open, setParaId, setConfig, setTab],
  );

  const switchAppchainSidebarTab = useCallback(
    (tab: ManageAppchainTab) => setTab(tab),
    [setTab],
  );

  const closeAppchainSidebar = useCallback(() => {
    close();
    setTab(ManageAppchainTab.Management);
    setParaId(undefined);
    setConfig(undefined);
  }, [close, setTab, setParaId, setConfig]);

  return {
    openAppchainSidebar,
    switchAppchainSidebarTab,
    closeAppchainSidebar,
  };
}
