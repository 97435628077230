export function isDefined<T>(param: T | null | undefined): param is T {
  return param !== undefined && param !== null;
}

export function areDefined<T extends unknown[]>(...params: T): boolean {
  return params.every(isDefined);
}

export function capitalize(text: string): string {
  if (!text) {
    return '';
  }

  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function chunk<T>(array: T[], size: number): T[][] {
  const result: T[][] = [];

  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }

  return result;
}
