import { useProxy } from '@/components/Proxy/state/proxy.hooks';
import { useManageAppchainState } from '@/components/pages/Dashboard/ManageAppchain/state';
import { ChainConfig } from '@/config';
import { useAppchainSudoKey } from '@/hooks/polkadot/appchain';
import { useProxies } from '@/hooks/polkadot/proxy';
import { useExtensions } from '@/state/polkadot-extension';
import { useMemo } from 'react';

export function useAppchainSudo(
  paramParaId?: number,
  paramConfig?: ChainConfig,
) {
  const { paraId: manageParaId, config: manageConfig } =
    useManageAppchainState();

  const paraId = paramParaId || manageParaId;
  const config = paramConfig || manageConfig;

  const { proxy } = useProxy();
  const sudoKey = useAppchainSudoKey(paraId, config);
  const allProxies = useProxies(sudoKey, paraId, config);
  const { checkAccountAvailability, isLoading } = useExtensions();

  const isSudoAvailable = useMemo(
    () => checkAccountAvailability(sudoKey),
    [checkAccountAvailability, sudoKey],
  );

  const proxies = useMemo(
    () =>
      allProxies?.filter(
        (proxy) =>
          proxy.proxyType === 'Any' &&
          !proxy.delay &&
          checkAccountAvailability(proxy.delegate),
      ),
    [checkAccountAvailability, allProxies],
  );

  return {
    isSudoAvailable,
    sudoKey,
    isLoading,
    isProxyAvailable: !!proxies?.length,
    isSudoOrProxyAvailable: isSudoAvailable || !!proxies?.length,
    hasSudoRights: isSudoAvailable || (!!proxies?.length && !!proxy),
    proxies,
  };
}
