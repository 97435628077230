import {} from '@/components/Sidebar';
import { ManageAppchainSudoActionsSidebar } from '@/components/pages/Dashboard/ManageAppchain/ManageAppchainSudoActionsSidebar';
import { SudoSidebarWrapper } from '../SudoSidebarWrapper';
import {
  ManageAppchainTab,
  getTabInfo,
} from '../state/ManageAppchain.constants';

const buttons = [ManageAppchainTab.Runtime].map(getTabInfo);

export function Maintenance() {
  return (
    <SudoSidebarWrapper
      tab={ManageAppchainTab.Maintenance}
      goBack={ManageAppchainTab.Management}
      goToOnProxyDisconnect={ManageAppchainTab.Maintenance}
    >
      <ManageAppchainSudoActionsSidebar buttons={buttons} />
    </SudoSidebarWrapper>
  );
}
