import {
  useCreateAppChainInfo,
  useUserChainDataState,
} from '@/components/pages/CreateAppChain/state/create.hooks';
import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import { useApiNotifications } from '@/hooks/notifications/useApiNotifications';
import { useCreateAppchainLogger } from '@/hooks/register/useCreateAppchainLogger';
import { useChainConfig } from '@/hooks/useChainConfig';
import { useCreateAppchainFiles } from '@/hooks/useCreateAppchainFiles';
import { useUser } from '@/state/user';
import { trpc } from '@/utils/trpc';
import { useSession } from 'next-auth/react';

export function useCreateAppchainItem() {
  const config = useChainConfig();
  const userAccount = useUser();
  const { paraId } = useCreateAppChainInfo();
  const { chainData } = useUserChainDataState();
  const { data: session } = useSession();
  const { specRaw } = useCreateAppchainFiles(paraId, config);
  const { logInfo } = useCreateAppchainLogger();
  const { clientError } = useApiNotifications();

  const { isPending, mutate } = trpc.appchains.createItem.useMutation({
    onError: (error) =>
      clientError('Failed to create appchain item', {
        error,
        paraId,
        chainData,
        session,
        chain: config.name,
      }),
    onSuccess: () => {
      logInfo('Appchain item was successfully created', Step.Register, {
        appchainUrl: chainData?.appchainUrl,
        x: chainData?.x,
        email: session?.user?.email,
        userName: session?.user?.name,
        iconImageBase64: chainData?.iconImage?.content,
        isPending: true,
      });
    },
  });

  return {
    isPending,
    createAppchainItem: () => {
      if (!userAccount || !paraId) {
        clientError('Failed to create appchain: missing data', {
          chain: config.name,
          paraId,
          chainData,
          session,
        });

        return;
      }

      mutate({
        paraId,
        chainName: specRaw?.name || paraId.toString(),
        appchainUrl: chainData?.appchainUrl,
        x: chainData?.x,
        address: userAccount.address,
        chainKey: config.key,
        email: session?.user?.email,
        userName: session?.user?.name,
        iconImageBase64: chainData?.iconImage?.content,
        isPending: true,
      });
    },
  };
}
