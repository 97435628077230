import { AlertIcon } from '@/components/icons/AlertIcon';
import {
  ActionIcon,
  Group,
  GroupProps,
  MantineSize,
  Text,
  lighten,
  rgba,
  useMantineTheme,
} from '@mantine/core';
import { IconX } from '@tabler/icons-react';

interface Props extends GroupProps {
  children: string | React.ReactNode;
  color?: string;
  textSize?: MantineSize;
  onClose?: () => void;
}

export function Alert({
  textSize = 'xs',
  children,
  color,
  onClose,
  ...others
}: Props) {
  const theme = useMantineTheme();

  return (
    <Group
      p={'xs'}
      bg={rgba(theme.other.colors.blue, 0.15)}
      gap={5}
      wrap={'nowrap'}
      pos={'relative'}
      {...others}
      style={{
        borderRadius: 8,
        border: `1px solid ${lighten('white', 0.2)}`,
        ...others.style,
      }}
    >
      <AlertIcon mr={5} color={color} />
      {typeof children === 'string' ? (
        <Text size={textSize} c={'white'} style={{ flexGrow: 1 }}>
          {children}
        </Text>
      ) : (
        children
      )}
      {onClose && (
        <ActionIcon
          pos={'absolute'}
          top={-7}
          right={-7}
          size={20}
          bg={'gray.9'}
          style={{ border: '1px solid var(--mantine-color-gray-7)' }}
          onClick={onClose}
        >
          <IconX size={13} stroke={3} />
        </ActionIcon>
      )}
    </Group>
  );
}
