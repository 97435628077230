import { showNotification } from '@mantine/notifications';
import * as Sentry from '@sentry/nextjs';
import { useMemo } from 'react';

export interface ApiNotifications {
  clientError: (message: string, extra?: Record<string, unknown>) => void;
}

export const useApiNotifications = (): ApiNotifications => {
  return useMemo(
    (): ApiNotifications => ({
      clientError: (
        error: string | Error,
        extra?: Record<string, unknown>,
      ): void => {
        const message = typeof error === 'string' ? error : error.message;

        console.error(error);

        Sentry.captureException(message, {
          level: 'error',
          extra: { ...extra, error },
        });

        showNotification({
          title: 'Internal server error',
          message: `Something went wrong, please try again later.`,
          color: 'red',
        });
      },
    }),
    [],
  );
};
