import { SidebarHeader, SidebarStack } from '@/components/Sidebar';
import {
  SidebarLink,
  SidebarOffsiteLink,
} from '@/components/Sidebar/SidebarOffsiteLink';
import {
  ManageAppchainTab,
  getTabInfo,
} from '../state/ManageAppchain.constants';

const SIDEBAR_INDEXERS = [
  {
    name: 'SQD',
    description:
      'SQD enables permissionless, cost-efficient access to petabytes of high-value Web3 data.',
    url: 'https://app.deform.cc/form/699798a0-73d9-414c-bfc2-697950b7fbd7',
    logo: '/images/logo_sqd.svg',
  },
] satisfies SidebarLink[];

export function Indexers() {
  return (
    <>
      <SidebarHeader
        title={getTabInfo(ManageAppchainTab.Indexers).title}
        goBack={ManageAppchainTab.Management}
      />

      <SidebarStack>
        {SIDEBAR_INDEXERS.map(({ logo, name, description, url }) => (
          <SidebarOffsiteLink
            key={name}
            logo={logo}
            name={name}
            description={description}
            url={url}
          />
        ))}
      </SidebarStack>
    </>
  );
}
