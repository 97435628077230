import { ChainConfig, ChainKey } from '@/config';
import { useAppchainSudoKey } from '@/hooks/polkadot/appchain';
import { trpc } from '@/utils/trpc';

export function useAppchainRuntimeVersions(
  paraId: number | undefined,
  config: ChainConfig | undefined,
  template: string | undefined,
) {
  const sudo = useAppchainSudoKey(paraId, config);

  return trpc.appchains.getRuntimeVersions.useQuery(
    {
      paraId: paraId || 0,
      address: sudo || '',
      template: template || '',
      chainKey: config?.key || ChainKey.Dancebox,
    },
    {
      enabled: !!paraId && !!config && !!template && !!sudo,
      refetchOnWindowFocus: false,
    },
  );
}
