import { minidenticon } from 'minidenticons';
import Image from 'next/image';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface IdentityIconProps {
  value: string;
  size?: number;
  iconImage?: {
    type: string;
    content: string;
  };
  saturation?: string | number;
  lightness?: string | number;
}

export function IdentityIcon({
  iconImage,
  value,
  size = 65,
  saturation,
  lightness,
}: IdentityIconProps) {
  const svgText = useMemo(
    () => minidenticon(value || uuidv4(), saturation, lightness),
    [value, saturation, lightness],
  );

  let src: string = `data:image/svg+xml;utf8,${encodeURIComponent(svgText)}`;

  if (iconImage) {
    src = `data:${iconImage.type};base64,${iconImage.content}`;
  }

  return (
    <Image
      src={src}
      alt={value}
      width={size}
      height={size}
      style={{ borderRadius: 'inherit' }}
    />
  );
}
