import { ChainConfig } from '@/config';
import { FrameSystemLastRuntimeUpgradeInfo } from '@polkadot/types/lookup';
import { useApi } from '../../useApi';
import { useApiCall } from '../../useApiCall';

export function useTanssiRuntimeVersion(
  config: ChainConfig,
): number | undefined {
  const api = useApi(config.ws);

  return useApiCall(api?.query.system.lastRuntimeUpgrade, [], transform);
}

function transform(
  lastRuntimeUpgrade: FrameSystemLastRuntimeUpgradeInfo,
): number {
  return lastRuntimeUpgrade.specVersion.toNumber();
}
