import { getPolkadotHttpApi } from '@/utils/polkadot';
import { showNotification } from '@mantine/notifications';
import { ApiPromise } from '@polkadot/api';
import { useEffect, useState } from 'react';

export function useApiHttp(
  endpoint: string | undefined,
): ApiPromise | undefined {
  const [api, setApi] = useState<ApiPromise | undefined>();

  useEffect(() => {
    if (endpoint) {
      getPolkadotHttpApi(endpoint)
        .then(setApi)
        .catch((error) => {
          console.error(error);
          showNotification({
            message: 'Error connecting to the network. Please try again later.',
            title: 'Connection error',
            color: 'red',
          });
        });
    } else {
      setApi(undefined);
    }
  }, [endpoint]);

  return api;
}
