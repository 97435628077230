import { ChainConfig } from '@/config';
import { useApiCall } from '@/hooks/polkadot';
import { useAppchainApi } from '@/hooks/polkadot/appchain';
import { FrameSystemLastRuntimeUpgradeInfo } from '@polkadot/types/lookup';

interface Runtime {
  version: number;
  template: string;
}

export function useAppchainCurrentRuntime(
  paraId: number | undefined,
  config: ChainConfig | undefined,
): Runtime | undefined {
  const api = useAppchainApi(paraId, config);

  return useApiCall(api?.query.system.lastRuntimeUpgrade, [], transform);
}

function transform(chain: FrameSystemLastRuntimeUpgradeInfo): Runtime {
  return {
    version: chain.specVersion.toNumber(),
    template: chain.specName.toString(),
  };
}
