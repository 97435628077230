import { SidebarHeader, SidebarStack } from '@/components/Sidebar';
import { SidebarBlockscoutButton } from '@/components/Sidebar/SidebarBlockscoutButton';
import {
  SidebarLink,
  SidebarOffsiteLink,
} from '@/components/Sidebar/SidebarOffsiteLink';
import { useManageAppchainState } from '@/components/pages/Dashboard/ManageAppchain/state';
import { EXPLORERS } from '@/config';
import { useAppchainUrls } from '@/hooks/polkadot/appchain';
import {
  ManageAppchainTab,
  getTabInfo,
} from '../state/ManageAppchain.constants';

const SIDEBAR_EXPLORERS = [
  {
    name: EXPLORERS.evm.name,
    description:
      'Set of tools to manage your appchain token, mint, transfer and update balances.',
    logo: EXPLORERS.evm.logo,
  },
  {
    name: EXPLORERS.polkadotJs.name,
    description:
      'Comprehensive tool for viewing on-going activity and advanced querying',
    logo: EXPLORERS.polkadotJs.logo,
  },
  {
    name: EXPLORERS.blockscout.name,
    description: 'Advanced explorer',
    logo: EXPLORERS.blockscout.logo,
  },
] satisfies SidebarLink[];

interface Props {
  closeOnBack?: boolean;
}

export function Explorers({ closeOnBack }: Props) {
  const { paraId, config } = useManageAppchainState();
  const urls = useAppchainUrls(paraId, config);

  return (
    <>
      <SidebarHeader
        title={getTabInfo(ManageAppchainTab.Explorers).title}
        goBack={closeOnBack ? undefined : ManageAppchainTab.Management}
      />

      <SidebarStack>
        {SIDEBAR_EXPLORERS.map(({ name, description, logo }) => {
          const url = urls?.explorers.find((e) => e.name === name)?.url;

          if (name !== EXPLORERS.blockscout.name && url) {
            return (
              <SidebarOffsiteLink
                key={name}
                name={name}
                description={description}
                url={url}
                logo={logo}
              />
            );
          }

          return (
            <SidebarBlockscoutButton
              key={name}
              description={description}
              logo={logo}
            />
          );
        })}
      </SidebarStack>
    </>
  );
}
