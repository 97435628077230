import '@polkadot/api-augment/polkadot';

import { ChainConfig } from '@/config';
import { useApiCall } from '@/hooks/polkadot';

import type { Vec, u32 } from '@polkadot/types-codec';
import { useApi } from '../../useApi';

export function useParaIds(config?: ChainConfig): number[] | undefined {
  const api = useApi(config?.ws);

  return useApiCall(api?.query.registrar.registeredParaIds, [], transform);
}

function transform(paras: Vec<u32>): number[] | undefined {
  return paras.map((para) => para.toNumber());
}
