import { SelectChainCard } from '@/components/SelectChain/SelectChainCard';
import { flashbox } from '@/config';
import { useIsDanceboxRegistrationEnabledFlag } from '@/hooks/flags/useIsDanceboxRegistrationEnabledFlag';
import { useIsFlashboxRegistrationEnabledFlag } from '@/hooks/flags/useIsFlashboxRegistrationEnabledFlag';
import { useDedicatedChainKey } from '@/hooks/useDedicatedChainKey';
import { useSetChainKey } from '@/state/chain';
import { Grid, Stack } from '@mantine/core';
import { Alert } from '../Alert';

export function SelectChainCards() {
  const setChainKey = useSetChainKey();
  const dedicatedChainKey = useDedicatedChainKey();

  const flashboxFlag = useIsFlashboxRegistrationEnabledFlag();
  const isFlashboxDisabled = !flashboxFlag.isLoading && !flashboxFlag.isEnabled;

  const danceboxFlag = useIsDanceboxRegistrationEnabledFlag();
  const isDanceboxDisabled = !danceboxFlag.isLoading && !danceboxFlag.isEnabled;

  return (
    <Stack gap={'md'} mt={30}>
      {isFlashboxDisabled && flashboxFlag.text && (
        <Alert>{flashboxFlag.text}</Alert>
      )}
      {isDanceboxDisabled && danceboxFlag.text && (
        <Alert>{danceboxFlag.text}</Alert>
      )}
      <Grid>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <SelectChainCard
            titleChainType="Snap"
            description="Perfect for quick, trial-based deployments, Snap Appchains are auto-deprecated after 48 hours - ideal for brief testing phases."
            isDisabled={isFlashboxDisabled}
            chainKey={flashbox.key}
            openModal={open}
            setChainKey={setChainKey}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <SelectChainCard
            titleChainType="Dedicated"
            description="Ideal for teams needing a long-term test environment, this option provides a stable and enduring platform for your appchain development."
            isDisabled={isDanceboxDisabled}
            chainKey={dedicatedChainKey}
            openModal={open}
            setChainKey={setChainKey}
          />
        </Grid.Col>
      </Grid>
    </Stack>
  );
}
